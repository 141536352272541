.transaction-dialog {
    $invoice: #ff0000;
    $payment: #00ff1f;


    width: 920px;
    max-width: 920px;
    max-height: 600px;


    .md-padding {
        padding: 20px 20px 0;
    }

    .md-horz-padding {
        padding: 0 20px;
    }

    md-toolbar {
        padding-left: 20px;
        padding-right: 20px;
    }
    .md-toolbar-tools {
        height: auto;
        padding: 0;

        h2 {
            font-size: 18px;
        }
    }

    .progress-indication {
        position: absolute;
        width: 100%;
        height: 100%;
        display: inline-grid;
        z-index: 100;
        background: rgba(255, 255, 255, 0.8);

        &.ng-enter, &.ng-leave {
            transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
        }

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }
    }

    md-select.md-table-select {
        line-height: 2.2;
    }

    md-dialog-content.transaction {
        md-icon{
            vertical-align: baseline;
        }
    }

    md-tabs {
        md-content {
            background: rgba(0,0,0,0.03);
        }
    }

    md-dialog-actions {
        margin: 10px 10px;
    }


    //Top inputs
    .transaction {
        background: #fffbf5;
        border-bottom: 2px solid rgba(0,0,0,0.1);

        md-input-container.md-umbrella-theme:not(.md-input-invalid).md-input-has-value label,
        md-input-container.md-input-has-placeholder label:not(.md-no-float) {
            transform: translate3d(0, 4px, 0) scale(0.75);
            color: $umbPurple;
        }

        md-input-container {
            margin-bottom: 0;
        }

        input {
            padding-top: 18px;
            padding-bottom: 18px;
            font-size: 16px;

        }

        md-input-container .md-input.ng-invalid.ng-dirty {
            padding-bottom: 18px;
        }

        label {
            //color: $umbPurple;
        }

        .md-select-value, md-select:not([disabled]):focus .md-select-value {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .transaction_type { max-width: 22%} //override flex

        .payment_amount {
            max-width: 16%;
            padding-right: 0;
            input {
                border: 2px solid rgba($umbPurple, 1);
                background: #fff;
                text-align: right;
                padding-right: 10px;
                font-weight: bold;
                font-size: 20px;

                .ng-invalid.ng-touched {
                    border-color: red;
                }
            }

            &.md-input-invalid input{
                border-color: red;
            }
        }
    }


    // table.md-table tbody.md-body > tr.md-row, table.md-table tfoot.md-foot > tr.md-row{
    //     height: 30px;
    // }

    // table.md-table:not(.md-row-select) td.md-cell:first-child, table.md-table:not(.md-row-select) th.md-column:first-child {
    //     padding: 10px;
    // }

    td.md-cell, thead.md-head > tr.md-row th {
        padding: 0 10px!important;
    }

    tr:last-of-type td.md-cell {
        border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
    }

    // table.md-table thead.md-head > tr.md-row {
    //     height: 35px;
    // }

   table md-input-container .md-errors-spacer {
        display: none;
    }

    .md-table md-input-container.md-umbrella-theme {
        padding-right: 0;
    }


    //Table column definitions
    table.md-table td.md-cell, table.md-table th.md-column, table.md-table tfoot > tr td {
        position: relative;
        &.facility {
            width: 25%;
        }
        &.product {
            width: 35%;
        }
        &.qty {
            text-align: center;
            width: 8%;

            input {
                text-align: center;
            }
        }
        &.unit-price {
            width: 10%;

            input {
                text-align: right;
            }
        }

        &.owing {
            font-weight: bold;
        }

        &.amount-applied {
            width: 14%;
            padding-left: 20px!important;

            input {
                text-align: right;
            }
        }

        &.sub-total {
            width: 12%;
            font-weight: 700;
        }
        &.sub-total, &.invoice-total {
            text-align: right;
            padding-right: 15px!important;
        }

        &.invoice-total, &.invoice-total-label {
            text-align: right;
            padding-top: 10px;
            font-size: 14px;
            font-weight: 700;
        }

        &.subsidy {
            background: rgba($umbPurple, 0.10);
            width: 8%;
            padding: 0!important;
            text-align: center!important;
        }
        &.actions {
            width: 6%!important;
            padding: 0!important;
        }
    }


    //MD Select Option Group
    .md-select-value > span:nth-child(1) > div > span {
        display: none;
    }

    .btn-add-product {
        position: absolute;
        top: 0;
        margin-left: 0px;
        color: $umbPurple;

        md-icon {
            color: $umbPurple
        }
    }


    .message-container {
        padding: 10px 20px;

        text-align: center;

        p:first-child {
            margin: 0;
        }

        &.green {
            background: #d9f7cc;
        }
    }

}
