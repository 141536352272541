.accountStatement {

	-webkit-print-color-adjust: exact;
	//display: none;

	@media print {
		display: table-header-group;

		button {
			display: none;
		}
	}

	$tableWidth: 960px;

	$checkboxCellWidth: 20px;
	$transactionIDCellWidth: 60px;
	$datePostedCellWidth: 80px;
	$labelCellWidth: 350px;
	$balanceCellWidth: 90px;

	$facilityNameCellWidth: 160px;
	$productNameCellWidth: 295px;
	$qtyCellWidth: 30px;
	$unitPriceCellWidth: 70px;
	$subtotalCellWidth: 70px;
	$subsidyCellWidth: 66px;

	$cellSpacing: 20px;

	$selectedRow: rgb(255, 251, 245)!default;
	$borderSettings: 1px rgba($umbPurple, 0.5) solid;

	.transaction-data {
		margin-top: 10px;
	}
	table.md-table.md-row-select th.md-column:first-child {
		padding-left: 15px;
	}

	table.md-table td.md-cell, table.md-table th.md-column {
		&.invoice, &.payments, &.balance {
			//text-align: right;
			//width: 8%;
		}

		&.md-checkbox-cell {
			max-width: $checkboxCellWidth;
		}

		&.id {
			width: $transactionIDCellWidth;
		}

		&.label {
			width: $labelCellWidth;
		}

		&.post-date {
			width: $datePostedCellWidth;
		}



		&.balance {
			background: rgba($umbPurple, 0.10);
			width: $balanceCellWidth;
			border-left: $borderSettings;
			font-weight: 700;
		}

		&.facility-name {
			text-align: left;
			min-width: $facilityNameCellWidth;
		}
		&.product-name {
			text-align: left;
			min-width: $productNameCellWidth;
		}
		&.qty { width: $qtyCellWidth; text-align: center }
		&.unit-price { width: $unitPriceCellWidth; }
		&.sub-total { width: $subtotalCellWidth; }
		&.subsidy { width: $subsidyCellWidth; text-align: center; border-left: 1px solid rgba(0,0,0, 0.10); }
	}

	// table.md-table.md-row-select td.md-cell:nth-child(2), table.md-table.md-row-select th.md-column:nth-child(2) {
	// 	padding: 0;
	// 	padding-right: $cellSpacing;
	// }
	table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2), table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
		padding-right: $cellSpacing;
	}

	table.md-table.md-row-select tbody.md-body > tr.md-row.md-selected,
	.transaction-detail
	{
		background: $selectedRow;
	}

	.transaction-detail {

		// table.md-table td.md-cell:nth-child(n+3):nth-last-child(n+2), table.md-table th.md-column:nth-child(n+3):nth-last-child(n+2) {
		// 	padding-right: 10px;
		// }

		td.md-cell {
			border-top: 0;
			width: 0;
			font-size: 12px;
			text-align: right;

			&:not(.no-border) {
				border-bottom: 1px solid rgba(0,0,0,0.1);
			}

			&.unit-price, &.sub-total {
				//padding: 0;
				font-weight: 700;
				font-size: 13px;
			}


		}

		.summary {
			td {
				line-height: 1.8em;
			}
			&:nth-of-type(2) td {
				padding-top: 0.5em !important;
				// border-top: 1px solid black;
			}
			&:nth-last-of-type(1) td {
				padding-bottom: 0.5em !important;
			}

		}


		.invoice-details-cell {
			padding-bottom: 15px!important;
		}

		table.md-table thead.md-head > tr.md-row {
			height: 30px;

			th {
				font-size: 10px;
			}
		}

		table.md-table td.md-cell:last-child, table.md-table th.md-column:last-child {
			padding: 0 10px;
			//width: 10%;

		}

		table.md-table tbody.md-body > tr.md-row, table.md-table tfoot.md-foot > tr.md-row {
			height: 3em;

		}

		table tfoot td.md-cell {
			border: 0;
			padding: 5px 20px 5px 0px !important;
		}

		table tfoot tr:first-of-type td.md-cell {
			padding-top: 10px;
		}

		table tfoot tr:last-of-type td.md-cell {
			padding-bottom: 10px;
		}


	}

	.invoice-details {
		width: $facilityNameCellWidth + $productNameCellWidth + $qtyCellWidth + $unitPriceCellWidth + $subtotalCellWidth + $subsidyCellWidth + ($cellSpacing * 6) + $cellSpacing /2;
		background: #fff;
		border: 1px solid rgba($umbPurple, 0.4);
		display: inline-block;
		text-align: right;
		margin: 0px 0px 10px 0px;
		box-shadow: 1px 1px 3px rgba(0,0,0,0.2);

		h3 {
			font-weight: bold;
			color: #6e297a;
			padding: 6px 0 0 15px;
		}
	}


}