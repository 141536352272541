/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

@import url(//fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic);
// @import url(//fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:400,700,700italic,400italic);

// @font-face {
// 	font-family: 'Material Icons';
// 	font-style: normal;
// 	font-weight: 400;
// 	src: url(../../bower_components/material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
// 	src: local('Material Icons'),
// 			 local('MaterialIcons-Regular'),
// 			 url(../../bower_components/material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
// 			 url(../../bower_components/material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
// 			 url(../../bower_components/material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
// }

// Global Material overrides
@import '../assets/style/global';

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}

html {
	font-family: 'Roboto', 'Helvetica Neue', serif;
}

[layout=row] {
	flex-direction: row;
}

.umb-sidebar-left {
	a, a:active, a:visited {
		color: #6e297a;
		text-decoration: none;

		&:hover {
			color:darken(#6e297a, 5%);
			text-decoration: underline;
		}
	}
}

input[type=text] {
    //text-transform: capitalize;
}

md-autocomplete {
    margin-top: 40px;
    padding-left: 30px;
    display: inline-block;
    width: 50%;

    input {
		min-height: 32px;
	}

	&[disabled] {
		label {
			color: rgba(0,0,0,0.38);
		}
	}
}

md-autocomplete i.material-icons, md-autocomplete md-progress-circular {
    position: absolute;
    top: 10px;
    left: -30px;
}


md-input-container.md-umbrella-theme {
	padding-right: 20px;
}

._md-datepicker-floating-label.md-umbrella-theme {
	//max-height: 58px;

	// .md-errors-spacer {
	// 	min-height: auto;
	// }
}

table.md-table td.md-cell {
	font-size: 14px;
}

table.md-table.md-row-select td.md-cell:first-child {
	padding-left: 14px;
}

// .md-button.md-umbrella-theme[disabled] {
// 	background-color: rgba(0,0,0,0.1);
// }

md-card.md-umbrella-theme {
	background-color: #f8f8f8;
}

.md-datepicker-input {
	max-width: none;
}
.md-datepicker-input-container {
	width: 100%;
}

md-optgroup {
	label {
		background: $umbPurple;
		color: #fff;

	}

	md-option .md-text span {
		font-weight: bold;
	}
}

md-dialog.md-umbrella-theme {
	min-width: 600px;
	// width: 50%;
	// max-width: 600px;

	.md-dialog-content-body {
		line-height: 1.5em;
	}
}

.flex-button-container {
	button {
		margin-left: 2px;
		margin-right: 2px;

	}

	// &:not(.layout-fill) {
	// 	button {
	// 		width: 100%;
	// 	}
	// }
}

//Wrap add options in this class
.umb-add-container {
	padding: 1.5em 1.5em;
	border-radius: 2px;
	border: 1px solid rgba(0,0,0,0.1);
	background-color: #fffbf5;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);

	md-input-container .md-errors-spacer {
		min-height: auto;
	}

	 md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
	  	bottom: 8px!important;
	  }
}


//Person Side bar
.person-sidebar {

	h2 {
		width: 100%;
		font-size: 1.5em;
		text-align: center;
		margin-bottom: 0.8em;

		span {
			text-align: left;
			display: block;
			font-size: 12px;
		}
	}

	p {
		margin-bottom: 0;
	}

	a {
		font-weight: 700;
	}

	md-icon {
		font-size: 18px;
	}

	section {
		box-sizing: border-box;
		width: 100%;
		border-top: 1px solid rgba(0,0,0,0.1);
		font-size: 15px;
		text-align: left;
		line-height: 20px;

		> div {
			padding: 8px 0;
			display: flex;
			align-items: stretch;
			align-content: space-between;

			> *:first-child {
				padding-right: 0.25em;
			}
		}
	}

	.addresses {

		.address {

		}
	}

	.account-balance {
		strong {
			font-size: 160%;
		}
	}

	.payment-information {
		p {text-align: center;}
	}

	&.adult-sidebar {
		padding-top: 1.6em;

		h2 {
			text-align: left;
		}
	}


}

@media print {
	@page {
		size:  auto;   /* auto is the initial value */
		margin: 5mm 10mm;  /* this affects the margin in the printer settings */
	}

 	main-header, .person-sidebar, .umb-person-tabs { display: none !important; }

}

/**
 *  Do not remove the comment below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "common/addressLookup/addressLookup.scss";
@import "auth/login/login.scss";
@import "common/list/list.scss";
@import "common/mainHeader/mainHeader.scss";
@import "common/navbar/navbar.scss";
@import "common/sidenav/sidenav.scss";
@import "people/add/add.scss";
@import "people/manage/manage.scss";
@import "common/components/profile-image/profile-image.scss";
@import "people/adult/fee-schedule/feeSchedule.scss";
@import "people/adult/payment-info/payment-info.scss";
@import "people/child/additional-info/additional-info.scss";
@import "people/adult/statement/statement.scss";
@import "people/adult/statement/transaction.scss";
@import "people/child/enrollment/enrollment.scss";
@import "people/shared/associations/associations.scss";
@import "people/child/fee-splitting/feeSplitting.scss";
@import "people/shared/profile/profile.scss";
// endinjector
